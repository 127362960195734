import {
    faCheck,
    faCircleNotch,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import React from "react";
import {
    Alert,
    Button,
    ButtonToolbar,
    Col,
    Form,
    HelpBlock,
    Modal,
    Panel,
    PanelGroup,
    Row,
} from "react-bootstrap";
import Recaptcha from "react-google-recaptcha";
import { getCardType } from "../../api/Payment";
import { getToken } from "../../api/User";
import { RECAPTCHA_SITEKEY } from "../../core/constants";
import { GetDataPostData } from "../../helpers/DataPost";
import * as Resources from "../../resources";
import { HeaderTokenError } from "../../resources";
import {
    IAccount,
    IAlertMessage,
    IApplication,
    IInternationalization,
    IPayment,
    IPaymentTerms,
    IPayor,
    ISettings,
    IToken,
} from "../../types";
import { IFormError } from "../../types/IFormError";
import { getLanguageCode } from "../../utils/Intl";
import { Post } from "../../utils/Post";
import { getTemplateId } from "../../utils/Template";
import { CheckFieldValidity, CheckFormValidity } from "../../utils/Validation";
import { CancelButton, FinishButton } from "../Input";
import BankAccountPanel from "../Payment/BankAccountPanel";
import BillingPanel from "../Payment/BillingPanel";
import CreditCardPanel from "../Payment/CreditCardPanel";
import DebitCardPanel from "../Payment/DebitCardPanel";
import PaymentTermsAuto from "../Verify/PaymentTermsAuto";

export interface ITokenFormProps {
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
    history: History;
    payor: IPayor;
    initialPayment: IPayment;
    paymentTerms: IPaymentTerms;
    shareTokenWithGroup: boolean;
    locale: string;
    loadPaymentTerms: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number,
        feeAmount: number
    ) => void;
    updateAccepted: (value: boolean) => void;
    updateCaptcha: (value: string) => void;
    cancel: VoidFunction;
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
}

export interface ITokenFormState {
    id: string;
    payment: IPayment | IAccount;
    showModal: boolean;
    submitting: boolean;
    message: IAlertMessage;
    errors: IFormError;
    error: string | null;
}

class TokenForm extends React.Component<ITokenFormProps, ITokenFormState> {
    private recaptcha = React.createRef<Recaptcha>();

    constructor(props: ITokenFormProps) {
        super(props);
        this.state = {
            id: "payment-form",
            payment: Object.assign({}, props.initialPayment),
            message: {} as IAlertMessage,
            errors: {},
            error: "",
            submitting: false,
            showModal: false,
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTabSelect = this.handleTabSelect.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handlePhoneCountryChange =
            this.handlePhoneCountryChange.bind(this);
        this.handleCopyPayorInformation =
            this.handleCopyPayorInformation.bind(this);
        this.onResolved = this.onResolved.bind(this);
    }
    componentDidMount() {
        const { application, settings, payor } = this.props;
        const payment = { ...this.state.payment } as IPayment;
        this.loadPaymentTerms(payment.method);

        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
        }
        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
        }
        if (!settings.creditIsAccepted && payment.method === "credit") {
            if (settings.debitIsAccepted) {
                payment.method = "debit";
            } else {
                payment.method = "checking";
            }
        } else if (!settings.debitIsAccepted && payment.method === "debit") {
            if (settings.creditIsAccepted) {
                payment.method = "credit";
            } else {
                payment.method = "checking";
            }
        } else if (!settings.achIsAccepted && payment.method === "checking") {
            if (settings.creditIsAccepted) {
                payment.method = "credit";
            } else {
                payment.method = "debit";
            }
        }
        const id = "payment-form";
        this.props.updateAccepted(false);
        this.props.updateCaptcha("");
        this.setState({
            id: id,
            payment: payment,
        });
    }
    handleBlur(event: any) {
        CheckFieldValidity(event.target, this.handleError);
    }
    handleDialogClose(event: any) {
        this.setState({ showModal: false });
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    handleError(name: any, message: any) {
        return this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }
    handleChange(event: any) {
        const field = event.target.name;
        const payment = { ...this.state.payment };
        if (
            field === "country" &&
            (payment as IPayment).country === "US" &&
            event.target.value !== "US"
        ) {
            payment.state = "";
        }
        (payment as any)[field] = event.target.value;
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        if (field === "sendEmailOnACHReturn") {
            (payment as IPayment).sendEmailOnACHReturn = event.target.checked;
        }
        this.setState({
            payment: payment,
        });
    }

    private handlePhoneChange(phone: string): void {
        this.setState({
            payment: {
                ...this.state.payment,
                phone,
            },
        });
    }

    private checkRecaptcha = async () => {
        if (this.props.settings.requireReCaptcha) {
            this.setState({
                submitting: false,
            });
            const token = await this.recaptcha.current?.executeAsync();
            this.setState({
                submitting: true,
            });
            this.onResolved(token);
        } else {
            this.onResolved();
        }
    };

    private resetRecaptcha = () => {
        if (this.props.settings.requireReCaptcha && this.recaptcha) {
            this.recaptcha.current?.reset();
        }
    };

    private clearField = (field: any): void => {
        this.setState({
            payment: {
                ...this.state.payment,
                [field]: "",
            },
        });
    };

    private callCardType = (
        application: any,
        merchantName: any,
        language: any,
        cardProcessingMethod: any,
        cardNumber: any,
        setShowCardType: (cardType: string) => void
    ): void => {
        const { settings } = this.props;

        this.handleError("system", null);
        const request = getCardType(
            application,
            merchantName,
            language,
            cardProcessingMethod,
            cardNumber
        );
        request
            .then((result: any) => {
                if (result.isSuccessful) {
                    setShowCardType(result.paymentMethod);
                    this.setState({
                        payment: {
                            ...this.state.payment,
                            paymentMethod: result.paymentMethod,
                        } as IPayment,
                    });
                } else {
                    const helpText = (
                        <HelpBlock>{result.messages[0]}</HelpBlock>
                    );
                    this.handleError("cardNumber", helpText);
                }
            })
            .catch((reason: any) => {
                this.handleError(
                    "system",
                    Resources.MessageConvenienceFee(
                        settings.convenienceFeeLabel
                    )
                );
            });
    };

    handleSubmit(event: any) {
        event.preventDefault();
        if (!CheckFormValidity(this.state.id, this.handleFormErrors)) {
            this.resetRecaptcha();
            return false;
        }
       
        this.handleError("system", null);
        this.setState({
            submitting: true,
            error: null,
        });
        this.checkRecaptcha();
    }
    handleTabSelect(method: any) {
        this.loadPaymentTerms(method);
        this.setState({
            payment: {
                ...this.state.payment,
                method,
                cardNumber: "",
                nameOnAccount: "",
                expirationMonth: "",
                expirationYear: "",
                cvc: "",
                accountType: "",
                routingNumber: "",
                accountNumber: "",
                confirmAccountNumber: "",
                convenienceFee: null,
                total: null,
            } as IPayment,
            errors: {},
            error: null,
        });
    }
    handlePhoneCountryChange(country: any) {
        this.setState({
            payment: {
                ...this.state.payment,
                phoneCountry: country,
            },
        });
    }
    handleCopyPayorInformation() {
        const { payor } = this.props;

        this.setState({
            payment: {
                ...this.state.payment,
                business: payor.business,
                firstName: payor.firstName,
                middleName: payor.middleName,
                lastName: payor.lastName,
                address: payor.address,
                country: payor.country,
                state: payor.state,
                city: payor.city,
                postal: payor.postal,
                phoneCountry: payor.phoneCountry,
                phone: payor.phone,
            },
            errors: {
                ...this.state.errors,
                phone: "",
            },
        });
    }
    loadPaymentTerms(method: string) {
        let paymentMethod = 0;
        switch (method) {
            case "credit":
                paymentMethod = 1;
                break;
            case "debit":
                paymentMethod = 9;
                break;
            case "checking":
                paymentMethod = 7;
                break;
            default:
                break;
        }

        this.props.loadPaymentTerms(
            this.props.application,
            this.props.settings.merchantName,
            getTemplateId(paymentMethod, this.props.settings),
            paymentMethod,
            getLanguageCode(this.props.intl.locale),
            (this.state.payment as IPayment).convenienceFee || 0
        );
    }
    onResolved(token?: string | null) {
        const { application, intl, settings, shareTokenWithGroup } = this.props;

        getToken(
            application,
            settings.merchantName,
            getLanguageCode(intl.locale),
            this.state.payment as IPayment,
            shareTokenWithGroup,
            token
        )
            .then((result: IToken) => {
                const payment = { ...this.state.payment };

                if (result.isSuccessful) {
                    (payment as IAccount).token = result.token;
                    payment.paymentMethod = result.paymentMethod;

                    if (
                        application.id !== 33 &&
                        !settings.showTokenReceipt &&
                        settings.returnUrl
                    ) {
                        const data = GetDataPostData(
                            application,
                            null,
                            payment,
                            null
                        );

                        Post(settings.returnUrl, data);
                    } else {
                        this.setState({
                            payment,
                            showModal: true,
                            submitting: false,
                        });
                    }
                } else {
                    const helpText = (
                        <HelpBlock>
                            {result.messages.reduce(
                                (prev, current) =>
                                    prev ? `${prev}, ${current}` : current,
                                ""
                            )}
                        </HelpBlock>
                    );
                    this.handleError("cardNumber", helpText);
                    this.handleError("routingNumber", helpText);
                    this.resetRecaptcha();

                    this.setState({
                        showModal: false,
                        submitting: false,
                    });
                }
            })
            .catch((reason: any) => {
                this.handleError(
                    "system",
                    "There was an error processing your request, please try again later."
                );
                this.resetRecaptcha();
                this.setState({
                    submitting: false,
                });
            });
    }
    render() {
        const { intl, application, settings, payor } = this.props;
        const { id, payment, submitting, error, errors, showModal } =
            this.state;
        const saveButton = submitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
            settings.tokenReplacementText || Resources.TextToken
        );
        const smallIcon = <span className="glyphicon glyphicon-chevron-right" style={{ fontSize: "x-small" }} />;
        const expandedIcon = <span className="glyphicon glyphicon-chevron-down" style={{ fontSize: "x-small" }} />;

        return (
            <div>
                <Form id={id}>
                    <div className="well">
                        <Row>
                            <Col xs={12} sm={6} md={6}>
                                <fieldset>
                                    <legend>
                                        {Resources.HeaderAccountInformation}
                                    </legend>
                                    <PanelGroup
                                        accordion
                                        id="PaymentInformation"
                                        activeKey={(payment as IPayment).method}
                                        onSelect={this.handleTabSelect}>
                                        <Panel eventKey="credit">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    {(payment as IPayment).method === "credit" ? expandedIcon : smallIcon}
                                                    &nbsp;
                                                    <span className="hidden-xs hidden-sm">
                                                        {
                                                            Resources.TabCreditCard
                                                        }
                                                    </span>
                                                    <span className="hidden-md hidden-lg">
                                                        {
                                                            Resources.TabCreditCardSm
                                                        }
                                                    </span>
                                                    &nbsp;
                                                    <span style={{ float: "right" }}>
                                                        {settings.visaIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-visa" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                        {settings.masterCardIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-mastercard" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                        {settings.discoverIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-discover" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                        {settings.americanExpressIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-amex" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                    </span>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <CreditCardPanel
                                                    merchant={settings}
                                                    payment={payment}
                                                    errors={errors}
                                                    application={ application }
                                                    onChange={this.handleChange}
                                                    onError={ this.handleError}
                                                    onBlur={ this.handleBlur}
                                                    hideFeePanel
                                                    hideFutureDated={ true}
                                                    callCardType={this.callCardType}
                                                />
                                            </Panel.Body>
                                        </Panel>
                                        <Panel eventKey="debit">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    {(payment as IPayment).method === "debit" ? expandedIcon : smallIcon}
                                                    &nbsp;
                                                    <span className="hidden-xs hidden-sm">
                                                        {
                                                            Resources.TabDebitCard
                                                        }
                                                    </span>
                                                    <span className="hidden-md hidden-lg">
                                                        {
                                                            Resources.TabDebitCardSm
                                                        }
                                                    </span>
                                                    &nbsp;
                                                    <span style={{ float: "right" }}>
                                                        {settings.visaDebitIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-visa" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                        {settings.masterCardDebitIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-mastercard" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                        {settings.discoverDebitIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-discover" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                        {settings.americanExpressDebitIsAccepted && (
                                                            <span>
                                                                <i className="pay-method-icon pmtype-amex" />
                                                                &nbsp;{" "}
                                                            </span>
                                                        )}
                                                    </span>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <DebitCardPanel
                                                    merchant={settings}
                                                    payment={payment}
                                                    errors={errors}
                                                    application={ application }
                                                    onChange={
                                                        this.handleChange
                                                    }
                                                    onError={
                                                        this.handleError
                                                    }
                                                    onBlur={
                                                        this.handleBlur
                                                    }
                                                    hideFeePanel
                                                    hideFutureDated={
                                                        true
                                                    }
                                                    callCardType={
                                                        this.callCardType
                                                    }
                                                />
                                            </Panel.Body>
                                        </Panel>
                                        <Panel eventKey="checking">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    {(payment as IPayment).method === "checking" ? expandedIcon : smallIcon}
                                                    &nbsp;
                                                        {
                                                            settings.payByAchPrompt
                                                        }
                                                    <span style={{ float: "right" }} >
                                                        <i className="pay-method-icon pmtype-ach" />
                                                        &nbsp;{" "}
                                                    </span>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <BankAccountPanel
                                                    merchant={settings}
                                                    payment={payment}
                                                    errors={errors}
                                                    application={
                                                        application
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChange
                                                    }
                                                    onError={
                                                        this.handleError
                                                    }
                                                    onBlur={
                                                        this.handleBlur
                                                    }
                                                    hideFeePanel
                                                    hideFutureDated={
                                                        true
                                                    }
                                                    locale={
                                                        this.props
                                                            .locale
                                                    }
                                                    clearField={
                                                        this.clearField
                                                    }
                                                />
                                            </Panel.Body>
                                        </Panel>

                                    </PanelGroup>
                                </fieldset>
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <fieldset>
                                    <legend>
                                        {(payment as IPayment).method ===
                                        "checking"
                                            ? Resources.HeaderAccountHolder
                                            : Resources.HeaderCardHolder}
                                    </legend>
                                    <BillingPanel
                                        application={application}
                                        merchant={settings}
                                        payor={payor}
                                        payment={payment}
                                        errors={errors}
                                        onChange={this.handleChange}
                                        onPhoneChange={this.handlePhoneChange}
                                        onError={this.handleError}
                                        onBlur={this.handleBlur}
                                        phoneCountryChange={
                                            this.handlePhoneCountryChange
                                        }
                                        hideCopyPayor
                                        hideCopyProfile
                                        copyPayorInformation={
                                            this.handleCopyPayorInformation
                                        }
                                        showACHReturnNotification={false}
                                    />
                                </fieldset>
                            </Col>
                        </Row>
                    </div>
                    {application.id !== 35 && (
                        <Row>
                            <Col xs={12} sm={12}>
                                <div>
                                    <PaymentTermsAuto
                                        customText={settings.tokenReplacementText || Resources.TextToken }
                                        settings={settings}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={12} sm={12}>
                            {error && (
                                <Alert bsStyle="danger">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />{" "}
                                    <strong>{HeaderTokenError}</strong> {error}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                    <div className="well">
                        <Row>
                            <Col xs={12}>
                                <ButtonToolbar>
                                    <div className="btn-group btn-group-justified-sm-12">
                                        <CancelButton
                                            application={application}
                                            settings={settings}
                                            cancel={this.props.cancel}
                                            history={this.props.history}
                                        />
                                    </div>
                                    <div className="btn-group btn-group-justified-sm-12 pull-right">
                                        <Button
                                            bsClass="btn btn-primary btn-justified"
                                            style={{ minWidth: "6.5em" }}
                                            disabled={submitting}
                                            onClick={this.handleSubmit}
                                        >
                                            {saveButton}
                                        </Button>
                                        {settings.requireReCaptcha && (
                                            <div style={{ display: "none" }}>
                                                <Recaptcha
                                                    ref={this.recaptcha}
                                                    hl={intl.locale}
                                                    sitekey={RECAPTCHA_SITEKEY}
                                                    size="invisible"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </div>
                </Form>
                <Modal show={showModal} onHide={this.handleDialogClose}>
                    <Modal.Header>
                        <Modal.Title>{Resources.HeaderSuccess}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FontAwesomeIcon icon={faCheck} />{" "}
                        {Resources.MessageSuccessCustom(
                            settings.tokenReplacementText || Resources.TextToken
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <ButtonToolbar>
                                <FinishButton
                                    {...this.props}
                                    payment={payment as IPayment}
                                />
                            </ButtonToolbar>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default TokenForm;
