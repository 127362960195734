import { Reducer } from 'redux';
import { CLEAR, CANCEL } from '../actionTypes/Common';
import { CONFIGURATION_LOAD_SUCCESS, CONFIGURATION_EBPP_LOAD_SUCCESS } from '../actionTypes/Configuration';
import { PAYMENT_UPDATE, UpdatePaymentAction, PaymentActions } from '../actionTypes/Payment';
import { IPayment } from '../types';

export const initialState: IPayment = {
    method: 'credit',
    paymentMethod: 0,
    nameOnAccount: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: (new Date().getFullYear()).toString(),
    cvc: "",
    accountType: "",
    routingNumber: "",
    accountNumber: "",
    confirmAccountNumber: "",
    business: "",
    firstName: "",
    middleName: "",
    lastName: "",
    nickName: '',
    address: "",
    country: "US",
    state: "",
    city: "",
    postal: "",
    phone: "",
    phoneCountry: "US",
    convenienceFee: null,
    total: null,
    termsAccepted: false,
    saved: false,
    type: '',
    email: '',
    saveAccountInMyAccountWallet: false,
    customerAccountName: '',
    mobilePhone: "",
    mobilePhoneCountry: "US",
    sendEmailOnACHReturn: false,
    futurePaymentDate: null,
    googlePayToken: "",
    applePayToken: ""
};

const paymentUpdateAction = (state: IPayment, action: UpdatePaymentAction): IPayment => {
    return {
        ...state,
        ...action.data
    };
};

export const reducer: Reducer<IPayment> = (state: IPayment = initialState, action: PaymentActions): IPayment => {
    switch (action.type) {
        case PAYMENT_UPDATE: return paymentUpdateAction(state, action);
        case CONFIGURATION_LOAD_SUCCESS: return {
            ...state,
            sendEmailOnACHReturn: action.data.settings.sendACHReturnEmailsToPayor && action.data.settings.emailIsRequired
        };
        case CONFIGURATION_EBPP_LOAD_SUCCESS: return {
            ...state,
            sendEmailOnACHReturn: action.data.settings.sendACHReturnEmailsToPayor && action.data.settings.emailIsRequired
        };
        case CLEAR: return { ...initialState };
        case CANCEL: return { ...initialState };
        default:
            return state;
    }
}